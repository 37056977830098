import React, {useState} from 'react';
//Countup
import CountUp from 'react-countup';
//Intersection observer hook
import {useInView} from 'react-intersection-observer';
//motion
import {motion} from 'framer-motion';
//variant
import {fadeIn} from '../variants';

const About = () => {
  const [state, setState] = useState(false);
  
  const toggle = () =>{
    setState(!state);
  }
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
   <section id='about' className='section' ref={ref}>
     <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* Img */}
          <motion.div
           variants={fadeIn('right', 0.3)}
           initial="hidden"
           whileInView={"show"}
           viewport={{once: false, amount: 0.3}}
          className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'>
            
          </motion.div>
          {/* Text */}
          <motion.div 
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
          className='flex-1'>

            <h2 className='h2 text-accent'>About me</h2>
            <h3 className='h3 mb-4'>I am a free Lancer full Stack developer, desgner and educator with over Five years of experience.</h3>
            <p className='mb-6'>I have worked on many succesful projects in the field of UI/UX design and mobile and web development.
              Leveraging frameworks like React, NodeJs Solidity and many design tools like figma, illustrator photoshop and Canva.
            </p>
            {/* Stats */}
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'>
                {
                  inView ? 
                  <CountUp start={0} end={5} duration={3} /> : 
                  null}
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                Years of <br/>
                Experience
              </div>
            </div>

            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'>
                {
                  inView ? 
                  <CountUp start={0} end={6} duration={3} /> : 
                  null}
                  +
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                Projects <br/>
                Completed
              </div>
            </div>

            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'>
                {
                  inView ? 
                  <CountUp start={0} end={10} duration={3} /> : 
                  null}
                  +
              </div>
              <div className='font-primary text-sm tracking-[2px]'>
                Satisfied <br/>
                Clients
              </div>
            </div>

              
              
            </div>
            <div className='flex gap-x-8 items-center'>
            <button onClick={toggle} className={'btn btn-sm text-lg ' + (state ? 'btn':'')} >
          {state ? '+256 700 988 025' : 'Contact me.'}
          </button>
              <a href='' className='text-gradient btn-link'>
                My Portfolio
              </a>
            </div>
          </motion.div>
        </div>
     </div>
    </section>
  );
};

export default About;
