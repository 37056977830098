import React, {useState} from 'react';

//React Icons
// import { FaPhone} from 'react-icons/fa';


const Header = () => {
  //Defining the useState hook to help me change the Button.
  const [state, setState] = useState(false);
  
  const toggle = () =>{
    setState(!state);
  }

  return <header className=' py-8'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>
        {/* logo */}
        <a href="./">
          <h2 className='flex-1 text-secondary'>@kakoozavian</h2>
          </a>
        {/* Button */}
        <button onClick={toggle} className={'btn btn-sm text-lg ' + (state ? 'btn':'')} >
          {state ? '+256 700 988 025' : 'Work with me.'}
          </button>
      </div>

    </div>
  </header>;
};

export default Header;
