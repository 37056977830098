import React from 'react';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants';
//img
import Img6 from '../assets/portfolio-img6.png';
import Img4 from '../assets/portfolio-img4.png';
import Img7 from '../assets/portfolio-img7.png';




const Work = () => {
  return (
   <section className='section' id='work'>
    <div className='container mx-auto'>
      <div
       className='flex flex-col lg:flex-row gap-x-10'>
        <motion.div 
          variants={fadeIn('right', 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 flex-col gap-y-12 mb-10 lg:mb-0'>
          {/* text */}
          <div className='flex-1 flex-col gap-y-12 mb-10 lg:mb-0'>
            <h2 className='h2 leading-tight text-accent'> My Latest <br/>Work.</h2>
            <p className='max-w-sm mb-16'>
              Always bringing the best. Never disapointing and great works.
              Enjoy through my finished work of art.
              
            </p>
            <button className='btn btn-sm mb-20'>View all projects</button>
          </div>
          {/* image */}
          <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
            <a href='https://dribbble.com/kakoozavian' alt=''>
            {/* Overlay */}
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            {/* Img */}
            <img className='group-hover:scale-125 transition-all duration-500' src={Img6} alt="" />
            {/* Pretitle */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient'>UI/UX Design</span>
              
            </div>
            {/* Title */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-3x1 text-white'>A collection of Designs </span>
            </div>
            </a>
          </div>
        </motion.div>
        <motion.div 
         variants={fadeIn('left', 0.2)}
         initial="hidden"
         whileInView={"show"}
         viewport={{once: false, amount: 0.3}}
         className='flex-1 flex flex-col gap-y-10'>
          {/* image */}
          <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
            <a href='https://yogrostore.com' alt='work 1'>
            {/* Overlay */}
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            {/* Img */}
            <img className='group-hover:scale-125 transition-all duration-500' src={Img4} alt="" />
            {/* Pretitle */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient'>YO GROSTORE</span>
              
            </div>
            {/* Title */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-3x1 text-white'>Buy all you need at home. </span>
            </div>
            </a>
          </div>
          {/* image */}
          <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
            <a href='https://cranewebhosting.com' alt=''>
            {/* Overlay */}
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            {/* Img */}
            <img className='group-hover:scale-125 transition-all duration-500' src={Img7} alt="" />
            {/* Pretitle */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient'>Web Hosting</span>
              
            </div>
            {/* Title */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-3x1 text-white'>Let's Talk hosting </span>
            </div>
            </a>
          </div>
        </motion.div>

        

      </div>
    </div>
   </section>
  );
};

export default Work;
