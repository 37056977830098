import React from 'react';
//icon
import {BsArrowUpRight} from 'react-icons/bs';
//motion
import {motion} from 'framer-motion';
//Variants
import {fadeIn} from '../variants';

//Services data
const services = [
  {
    name: 'UI/UX Design',
    description: 'Always designing and bringing the best for great works and unforgetable experience',
    link: 'Learn More'
  },
  {
    name: 'Development',
    description: 'Developed Stanning websites and Mobile Applications that provide an excellent user experience and mass impact',
    link: 'Learn More'
  },
  {
    name: 'Digital Marketing',
    description: 'Always reaching the desired audience and bringimg traction to your niche.',
    link: 'Learn More'
  },
  {
    name: 'Web3 and the Blockchain',
    description: 'Always leveraging new technologies such as web3 in providing solutions to present challenges.',
    link: 'Learn More'
  },
];


const Services = () => {
  return(
     <section className='section' id='services'>Services
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* Text & image */}
          <motion.div
           variants={fadeIn('right', 0.3)}
           initial="hidden"
           whileInView={"show"}
           viewport={{once: false, amount: 0.3}}
           className='flex-1 lg:bg-services lg:bg-bottom lg:bg-no-repeat mix-blend-lighten 
           mb-12 lg:mb-0'>
            <h2 className='h2 text-accent mb-6'>What I Do.</h2>
            <h3 className='h3 max-w-[455px] mb-16'>
              I am a free Lancer full Stack developer with over Five years of experience.
              </h3>
              <button className='btn btn-sm'>See my Work</button>
          </motion.div>
          {/* Services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1'>
            {/* Services List */}
            <div>
              {services.map((service, index)=> {
                //destructure service
                const {name, description, link} = service;
                return (
                  <div className='border-b border-white/20 h-[146px] mb-[38px] flex' key={index}>
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>{name}</h4>
                      <p className='font-secondary leading-tight'>{description}</p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <a href='#' className='btn w-9 h-9 mb-[42px] flex justify-center items-center'> 
                      <BsArrowUpRight />
                      </a>
                      <a href="#" className='text-gradient text-sm'>{link}</a>
                    </div>

                  </div>
                );
              })}
            </div>
          </motion.div>

        </div>
      </div>
     </section>
  );
};

export default Services;
