import React, {useState} from 'react';
//images
import Image from '../assets/avatar1.svg';

//icons
import {FaTwitter, FaWhatsapp, FaLinkedinIn, FaYoutube, FaGithub} from 'react-icons/fa';

//type animation
import {TypeAnimation} from 'react-type-animation';
// motion
import {motion} from 'framer-motion';
//Variants
import {fadeIn} from '../variants';
import { Button } from 'react-scroll';

const Banner = () => {
  const [state, setState] = useState(false);
  const toggle = () => {
    setState(!state);
  }
  return(
   <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
        {/* Text */}
        <div className='flex-1 text-center font-secondary lg:text-left'>
          <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}}
           className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
            KAKOOZA <span>VIANNEY</span>
          </motion.h1>
        <motion.div variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}}  
        className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
          <span className='text-white mr-4'>I am 
            {/* If text starts wth Vowel use an els a... Will have to be implemented */}
           </span>
          <TypeAnimation sequence={[
            'a Developer',
            2000,
            'a Designer',
            2000,
            'an Educator',
            2000,
            'a Technology Enthusiast',
            2000,
          ]}
            speed={50}
            className='text-accent'
            wrapper='span'
            repeat={Infinity}
          />
          
        </motion.div>  
        <motion.p variants={fadeIn('up', 0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}}
        className='mb-8 max-w-lg mx-auto lg:mx-0'>
           I am a very proficient and competent web developer, designer and Technology enthusast with over half a decade in technology. Here to work with you.
          </motion.p>
          <motion.div variants={fadeIn('up', 0.6)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}}
          className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
            <button onClick={toggle} className={'btn btn-sm text-lg ' + (state ? 'btn':'')} >
          {state ? '+256 700 988 025' : 'Contact Me.'}
          </button>
            <a href='#' className='text-gradient btn-link'>My Portfolio
            </a>
          </motion.div>
          {/* Socials */}
          <motion.div variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}}
          className='flex text-[30px] gap-x-8 max-w-max mx-auto lg:mx-0'>
            <a href='https://www.linkedin.com/in/vianney-kakooza-b30691182/'>
              <FaLinkedinIn />
            </a>
            <a href='https://twitter.com/kakoozavian'>
              <FaTwitter />
            </a>
            <a href='https://github.com/kaksv'>
              <FaGithub />
            </a>
            <a href='https://www.youtube.com/channel/UCuyYINreRHJcS5k7gQyTi9A'>
              <FaYoutube />
            </a>
            {/* <a href='#'>
              <FaFacebookMessenger />
            </a> */}
            {/* <a href='#'>
              <FaTelegram />
            </a> */}
            <a onClick={toggle} href='https://wa.link/2xhf7l' >
               <FaWhatsapp />
            </a>
          </motion.div>
        </div>
        {/* Image */}
        <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} 
        className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]' >
          <img src={Image} alt='' />
        </motion.div>

      </div>
    </div>
    </section>
  );
};

export default Banner;
